import React, {useRef, useEffect}  from "react";



const PolicyModal = ({policyMode, onCancel = ()=>{}}) => {
    const container = useRef(null)

    const privacy = `
    1. Введение
  Настоящая Политика конфиденциальности описывает, как наша система ведения студенческих проектов (далее – "Система") собирает, использует и защищает информацию, полученную от пользователей. Мы придаем большое значение конфиденциальности ваших данных и стремимся обеспечить их безопасность.
    2. Сбор информации
  Мы можем собирать следующую информацию:
      • Личные данные: имя, адрес электронной почты и другие данные, которые вы предоставляете при регистрации или использовании Системы.
      • Данные о проектах: информация, связанная с вашими проектами, включая описания, материалы и другую связанную информацию.
    3. Использование информации
  Собранные данные могут использоваться для следующих целей:
      • Обеспечение функционирования Системы.
      • Улучшение качества обслуживания и пользовательского опыта.
      • Связь с пользователями для уведомления об обновлениях и новостях.
      • Анализ использования Системы для ее дальнейшего улучшения.
    4. Передача данных третьим лицам
  Мы гарантируем, что ваша информация не будет передаваться третьим лицам без вашего предварительного согласия, за исключением случаев, предусмотренных законодательством. Мы не продаем, не обмениваем и не передаем ваши личные данные третьим лицам.
    5. Безопасность данных
  Мы принимаем все необходимые меры для защиты ваших данных от несанкционированного доступа, изменения, раскрытия или уничтожения. Однако ни один метод передачи данных через интернет или метод электронного хранения не является абсолютно безопасным. Мы стремимся использовать коммерчески приемлемые средства для защиты ваших данных, но не можем гарантировать их абсолютную безопасность.
    6. Ответственность
  Мы не несем ответственности за любые убытки или ущерб, возникшие в результате использования или невозможности использования Системы, а также за любые последствия, связанные с использованием вашей информации другими пользователями или третьими лицами.
    7. Изменения в Политике конфиденциальности
  Мы оставляем за собой право вносить изменения в настоящую Политику конфиденциальности. Все изменения будут опубликованы на этой странице с указанием даты последнего обновления. Мы рекомендуем периодически пересматривать эту Политику для получения актуальной информации о том, как мы защищаем вашу информацию.
  8. Контактная информация
  Если у вас есть вопросы или комментарии по поводу нашей Политики конфиденциальности, пожалуйста, свяжитесь с нами по электронной почте: [почта].
  Дата последнего обновления: 08.12.2024.

    `

    const rules=`
  1. Общие положения
  1.1. Настоящие правила (далее – "Правила") регулируют использование системы студенческих проектов (далее – "Система") всеми пользователями, включая студентов, преподавателей и администраторов.
  1.2. Используя Систему, вы соглашаетесь с настоящими Правилами. Если вы не согласны с ними, пожалуйста, прекратите использование Системы.
  2. Регистрация и учетная запись
  2.1. Для использования Системы необходимо пройти процедуру регистрации и создать учетную запись.
  2.2. Пользователь обязан предоставлять точную и актуальную информацию при регистрации. В случае изменения данных пользователь должен обновить информацию в своей учетной записи.
  2.3. Пользователь несет полную ответственность за безопасность своей учетной записи и за все действия, происходящие под его учетной записью.
  3. Использование Системы
  3.1. Пользователи обязуются использовать Систему только в законных целях и в соответствии с действующим законодательством.
  3.2. Запрещается:
    •	Загружать или публиковать материалы, нарушающие авторские права или права третьих лиц.
    •	Использовать ненормативную лексику, оскорбления или угрозы в отношении других пользователей.
    •	Распространять спам или рекламные материалы без разрешения.
    •	Пытаться получить несанкционированный доступ к другим учетным записям или системам.
  4. Права и обязанности пользователей
  4.1. Пользователи имеют право:
    •	Создавать и управлять своими проектами.
    •	Комментировать и оценивать проекты других пользователей.
    •	Получать доступ к обучающим материалам и ресурсам, предоставленным в Системе.
  4.2. Пользователи обязуются:
    •	Соблюдать правила этикета при взаимодействии с другими пользователями.
    •	Уважать интеллектуальную собственность других пользователей.
    •	Сообщать администрации о любых нарушениях правил или подозрительной активности.
  5. Ответственность
  5.1. Администрация оставляет за собой право блокировать или удалять учетные записи пользователей, нарушающих настоящие Правила.
  5.2. Пользователи несут ответственность за свои действия в Системе и за последствия, возникающие в результате их действий.
  6. Изменения в Правилах
  6.1. Администрация оставляет за собой право вносить изменения в настоящие Правила в любое время. Все изменения будут опубликованы на этой странице с указанием даты последнего обновления.
  6.2. Продолжение использования Системы после внесения изменений в Правила означает ваше согласие с этими изменениями.
  7. Контактная информация
  7.1. Если у вас есть вопросы или предложения по поводу настоящих Правил, пожалуйста, свяжитесь с администрацией системы по электронной почте: [почта].

  Дата последнего обновления: 08.12.2024
  `
  
    useEffect(() => {
      const handleEsc = (event) => {
        if (event.key === 'Escape') {
          onCancel();
        }
      };
      const handleClickOutside = (event) => {
        if (container.current  && !container.current.contains(event.target)) {
          onCancel();
        }
      };
      document.addEventListener('keydown', handleEsc);
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('keydown', handleEsc);
        document.removeEventListener('mousedown', handleClickOutside);
      };
      // eslint-disable-next-line
    }, []); 
  
    return (
      <div className="policy-modal" ref={container}>
        <span>{policyMode === 1 ? "Политика конфиденциальности MadProjects": "Правила пользования системой студенческих проектов MadProjects"}</span>
        <pre>{policyMode === 1 ? privacy: rules}</pre>
        
      </div>
    )
}

export default PolicyModal