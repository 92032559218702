import React from "react";

const NotFoundPage = () => {

  
    return (
      <div className="notfound-page">          
            <img src="https://http.cat//404" alt="Not Found"/>
      </div>
      
    );
  }
  
  export default NotFoundPage;